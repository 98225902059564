@font-face {
  font-family: pixeloidsans;
  src: url("../fonts/PixeloidSans.ttf");
}
@font-face {
  font-family: karmatic;
  src: url("../fonts/ka1.ttf");
}
@font-face {
  font-family: pixeloidsans-bold;
  src: url("../fonts/PixeloidSans.ttf");
}

.title-sub {
  font-family: karmatic;
  color: white;
}

@media (max-width: 767px) {
  .main-content-txt{
    font-size: small !important;
  }
  .title-sub {
    padding-top: 3rem;
    padding-bottom: 4rem;
    font-size: 1.6rem;
  }
  .heading-txt {
    font-size: 1.4rem;
  }
  .content-boxx {
    width: 85%;
    padding: 1rem;
    margin-bottom: 8rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .title-sub {
    padding-top: 3rem;
    padding-bottom: 4rem;
    font-size: 2.8rem;
  }
  .content-boxx {
    width: 80%;
    padding: 2rem;
    margin-bottom: 10rem;
  }
}

@media (min-width: 992px) {
  .title-sub {
    padding-top: 3rem;
    padding-bottom: 4rem;
    font-size: 2.6rem;
  }
  .heading-txt {
    font-size: 2.2rem;
  }
  .content-boxx {
    width: 60%;
    display: flex;
    padding: 2rem;
    margin-bottom: 12rem;
  }
}

.content-boxx {
  font-family: pixeloidsans;
  backdrop-filter: blur(23px);
  -webkit-backdrop-filter: blur(23px);
  background-color: rgba(217, 217, 217, 0.624);
  border: 2px solid rgba(39, 37, 37, 0.34);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.heading-txt {
  color: white;
  font-family: karmatic;
  text-align: left;
  padding-top: 2rem;
}

.tagline {
  
  font-weight: normal;
  text-align: left;
  color: #0b4d81;
  font-size: 1.1rem;
  margin-bottom: 0;
}

.dates-txt {
  font-weight: bold;
  color: #0b4d81;
  text-align: left;
  font-size: 1.1rem;
}

.main-content-txt {
  color: #083e65;
  font-size: 1.1rem;
  text-align: justify;
}

.abt-container {
  padding-bottom: 6rem;
}

.heading {
  color: white;
}

.abt-box {
  transition: transform 0.3s;
}

.abt-box:hover {
  transform: scale(1.1);
}