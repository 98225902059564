@font-face {
  font-family: pixeloidsans-bold;
  src: url("../fonts/PixeloidSans.ttf");
}
@font-face {
  font-family: pixeloidsans;
  src: url("../fonts/PixeloidSans.ttf");
}
@font-face {
  font-family: karmatic ;
  src: url("../fonts/ka1.ttf");
}

.card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.alert {
  background-color: #f44336;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.admin-cnt {
  margin-top: 6rem;
}

.admin-cnt h1 {
  font-family: karmatic;
}

.admin-buttons-cnt {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-bottom: 2rem;
  padding-right: 2rem;
}

.filter-forms {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.table-responsive {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.link-git {
  color: skyblue;
}

.remove-btn {
  color: #f44336;
  background: none;
  border: none;
  padding-bottom: 4px;
}

.remove-btn:hover {
  background: #f44336;
  border: #f44336;
  color: white;
  border-radius: 4px;
}

.modal {
  margin-top: 8rem;
}