@font-face {
    font-family: pixeloidsans;
    src: url("../fonts/PixeloidSans.ttf");
}
@font-face {
    font-family: karmatic;
    src: url("../fonts/ka1.ttf");
}
@font-face {
    font-family: pixeloidsans-bold;
    src: url("../fonts/PixeloidSans.ttf");
}

.title-sub {
    font-family: karmatic;
    color: white;
    text-align: center;
}
  
@media (max-width: 767px) {
    .title-sub {
        padding-top: 3rem;
        font-size: 1.6rem;
    }
    .image-container {
        width: 90%; 
        margin-left: auto;
        margin-right: auto;
    }
    .image-column img {
        width: 100%;
        height: 100%;
        object-fit: cover; 
        object-position: center; 
        padding-bottom: 2rem;
    }
}
  
@media (min-width: 768px) and (max-width: 991px) {
    .title-sub {
        padding-top: 3rem;
        padding-bottom: 4rem;
        font-size: 2.8rem;
    }
    .image-container {
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-template-rows: minmax(300px, auto);;
        gap: 16px;
        width: 90%; 
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .image-column {
        height: 300px;
    } 
    .image-column img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        object-position: center; 
    }
}
  
@media (min-width: 992px) {
    .title-sub {
        padding-top: 3rem;
        padding-bottom: 4rem;
        font-size: 2.6rem;
    }
    .image-container {
        display: grid;
        grid-template-columns: repeat(4, auto);
        grid-template-rows: minmax(300px, auto);;
        gap: 16px;
        width: 95%;
        height: auto; 
        margin-left: auto;
        margin-right: auto;
    }
    .image-column {
        height: 300px;
    }
    .image-column img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        object-position: center; 
    }
}

.double-width {
    grid-column: span 2; 
}

.double-height {
    grid-row: span 2;
    height: 616px;
}

.gallery {
    padding-bottom: 16rem;
}

.gal-img{
   padding: 2% 12%;
}

@media (max-width: 991px) {
    .image-container {
      display: none;
    }
    .gallery {
        padding-top: 6%;
        padding-bottom: 18rem;
    }
  }
  
  @media (min-width: 992px) {
    #carouselExampleAutoplaying {
      display: none;
    }
  }