@font-face {
  font-family: pixeloidsans;
  src: url("../fonts/PixeloidSans.ttf");
}
@font-face {
  font-family: karmatic;
  src: url("../fonts/ka1.ttf");
}
@font-face {
  font-family: pixeloidsans-bold;
  src: url("../fonts/PixeloidSans.ttf");
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  background: rgba(3, 22, 35, 0.895);
  padding: 10px 0;
  z-index: 1000;
}

.nav-link:visited {
  color: white;
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active {
  color: white;
}

.nav-heading {
  text-decoration: none;
  font-family: pixeloidsans-bold;
  color: white;
}

.container {
  display: flex !important;
  align-items: center !important;
}

.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: transform 0.3s ease-in-out;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 17px;
}

.nav-link {
  font-family: PixeloidSans-Bold;
  font-weight: bold;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: white;
}

.profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  
}

.mobile-profile-icon {
  display: none;
}

@media (max-width: 991px) {
  .burger-menu {
    display: flex;
  }

  .navbar-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    /* background: rgba(3, 22, 35, 0.895); */
    padding: 10px 0;
    text-align: center;
    background: rgba(3, 22, 35, 0.892);
 
  }

  .navbar-nav.active {
    display: flex;
  }

  .nav-item {
    margin: 10px 0;
  }

  .nav-heading {
    display: block;
  }

  .profile-icon {
    display: none;
  }

  .mobile-profile-icon {
    display: block;
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
    margin-right: 2.5rem;
    padding-right: 2.5rem;
  }

  .profile-icon {
    margin-left: 10px;
  }

  .container, .container-lg, .container-md, .container-sm {
    max-width: 100%;
  }
}

.nav-heading {
  font-size: 22px;
  transition: font-size 0.3s;

  @media (min-width: 768px) {
    font-size: 22px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
  }

  @media (min-width: 1200px) {
    font-size: 26px;
  }
}
