@font-face {
  font-family: pixeloidsans;
  src: url("../fonts/PixeloidSans.ttf");
}
@font-face {
  font-family: karmatic;
  src: url("../fonts/ka1.ttf");
}
@font-face {
  font-family: pixeloidsans-bold;
  src: url("../fonts/PixeloidSans.ttf");
}
#registration{
  font-family: karmatic;
}
input.form-control::-webkit-outer-spin-button,
input.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-reg-bg {
  color: white;
  font-family: karmatic;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
}

.kard {
  background: rgba(78, 156, 208, 0.206);
  padding-top: 8%;
  border-radius: 10px;
}

.title-reg {
  font-size: 2.8rem;
  padding-bottom: 3.2rem;
}

.reg-form {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.form-item {
  padding-bottom: 1rem;
}

.btn {
  font-size: 1.6rem;
  text-decoration: none;
}

.btn:hover {
  color: white;
}

.addButnContainer {
  text-align: right;
  margin-top: -1rem;
}

.addButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-weight: bold;
}

.addButton:hover {
  color: skyblue;
}

.form-control::placeholder {
  color: #0a3c62;
  font-family: pixeloidsans-bold;
  text-align: center;
}

.form-control {
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  background: #6bb5edcc;
  font-family: pixeloidsans-bold;
}

.form-control:focus {
  background: #6bb5edcc;
  border: 0px;
}
.btn{
  color: #FFF;
}
.btn:hover{
  scale: 1.1;
  transition: all 0.2s ease-in;
}

@media (max-width: 767px) {
  .custom-reg-bg {
    width: 90%;
    margin-top: 12rem;
  }
  .title-reg {
    font-size: 1.8rem;
    padding-bottom: 1.8rem;
  }
  .button-bar {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1rem;
  }
  .btn {
    font-size: 0.8rem;
    text-decoration: none;
  }
  .popup {
    padding: 1rem;
  }
  .popup-main-msg {
    font-size: 1rem;
  }
  .popup-msg {
    padding-top: 0.6rem;
    font-size: 0.6rem;
  }
  .popup-button-cnt {
    padding-top: 1rem;
  }
  .popup-btn {
    padding: 0.4rem 2rem;
    border-radius: 8px;
    background-color: #0a3c62;
    color: inherit;
    border: 4;
  }
  .error-handling {
    text-align: right;
    color: red;
    font-size: 0.8rem;
    font-family: pixeloidsans-bold;
    font-weight: bold;
    padding-bottom: -1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .custom-reg-bg {
    width: 80%;
    margin-top: 20rem;
  }
  .title-reg {
    font-size: 1.8rem;
    padding-bottom: 1.8rem;
  }
  .button-bar {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1rem;
  }
  .btn {
    font-size: 1.4rem;
    text-decoration: none;
  }
  .popup {
    padding: 2rem;
  }
  .popup-main-msg {
    font-size: 1.8rem;
  }
  .popup-msg {
    padding-top: 1rem;
    font-size: 1rem;
  }
  .popup-button-cnt {
    padding-top: 2rem;
  }
  .popup-btn {
    padding: 0.6rem 2.4rem;
    border-radius: 8px;
    background-color: #0a3c62;
    color: inherit;
    border: 4;
  }
}
@media (min-width: 992px) {
  .custom-reg-bg {
    color: white;
    background-size: cover;
  }
  .button-bar {
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-around;
  }
  .popup {
    padding: 2.4rem;
  }
  .popup-main-msg {
    font-size: 2rem;
  }
  .popup-msg {
    padding-top: 1rem;
    font-size: 1rem;
  }
  .popup-button-cnt {
    padding-top: 3rem;
  }
  .popup-btn {
    padding: 0.6rem 2.4rem;
    border-radius: 8px;
    background-color: #0a3c62;
    color: inherit;
    border: 4;
  }
}

.error-handling {
  text-align: right;
  color: rgb(207, 39, 39);
  font-family: pixeloidsans-bold;
  font-weight: bold;
  padding-bottom: -1rem;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-family: karmatic;
  font-size: 1.2rem;
  color: rgb(245, 245, 245);
  background: rgba(10, 60, 98, 0.455);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(11px);
  border-radius: 10px;
  border: 2px solid rgba(253, 253, 253, 0.198);
  z-index: 1000;
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  z-index: 999;
}

.error-msg {
  color: red;
}