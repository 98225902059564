.karma {
    white-space: nowrap;
    font-family: karmatic;
    font-size: 4rem;
    color: rgb(245, 245, 245);
    background: rgba(10, 60, 98, 0.455);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(11px);
    border-radius: 6px;
    border: 2px solid rgba(253, 253, 253, 0.198);
    margin-left: auto;
    margin-right: auto;
}

.dates {
    font-family: karmatic;
    z-index: 2;
    color: #47b9c3b3;
    filter: drop-shadow(3px 3px 5px #010101);
}

.tagline-register {
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, #0f5186 -5.91%, #0f5186 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 1.6rem;
    display: inline-block;
    color: white;
    transition: transform 0.3s ease;
}

.tagline-register:hover {
    transform: scale(1.1);
}

/* Small devices */
@media (max-width: 767px) {
    .karma {
        width: 90%;
        padding-bottom: 1%;
        padding-top: 4%;
        margin-top: 24rem;
        margin-bottom: 20rem;
    }

    .title {
        font-size: 2.2rem;
    }

    .dates {
        font-size: 1.2rem;
        margin-top: 1rem;
        top: 50%;
        left: 50%;
    }

    .tagline-register {
        transform: scale(0.8);
    }
}

/* Medium Devices */
@media (min-width: 768px) and (max-width: 991px) {
    .karma {
        width: 90%;
        padding-bottom: 8%;
        padding-top: 8%;
        padding-right: 2%;
        padding-left: 2%;
        margin-top: 30rem;
        margin-bottom: 32rem;
    }

    .title {
        font-size: 4.8rem;
    }

    .dates {
        color: #47b9c3b3;
        font-size: 2.4rem;
        top: 50%;
        left: 50%;
    }
}

/* Large devices */
@media (min-width: 992px) {
    .karma {
        width: 50%;
        padding-bottom: 1%;
        padding-top: 2%;
        margin-top: 24rem;
        margin-bottom: 24rem;
    }

    .title {
        font-size: 5rem;
    }

    .dates {
        color: #47b9c3b3;
        font-size: 2rem;
        top: 50%;
        left: 50%;
    }
}